export default function Home(props) {

    return (<>
        <div class="banner_wrapper">
            <div class="innerbanner">
                <div class="imagebox" style={{"background-image": "url(/assets/images/innerbanner.jpg)"}}></div>
                <div class="bann_captionbox">
                    <div class="container-fluid">
                        <h1>About Us</h1>
                        <div class="opentiming">100% Gluten Free</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ourstory_wrap">
            <div class="container">
                <div class="storybox">
                    <div class="titlebox flxrow"><h2>Somerset Cafe Story</h2></div>
                    <p>{`Welcome to our humble corner, where simplicity meets comfort at Somerset cafe. Tucked away from the hustle and bustle, we're not your average café – we're your home away from home.`}</p>
                </div>
            </div>
        </div>

        <div class="functionbook_sec">
            <div class="container">
                <div class="row flex-row-reverse">
                    <div class="col-lg-6">
                        <div class="imagebox"><img src="/assets/images/about.jpg" alt="" /></div>
                    </div>
                    <div class="col-lg-6">
                        <div class="captoinbox">
                            <h3>About Somerset Cafe</h3>
                            {/* <h6>Lorem Ipsum Text Here... Make it a Bouzy night to remember</h6> */}
                            <p className="pt-4">{`Step inside and be greeted by the inviting aroma of freshly ground coffee and the comforting hum of quiet conversations. Our ordinary café, with its unassuming charm, is a haven for those seeking a pause in their day, a casual meeting spot, or a solo retreat with a good book.`}</p>
                            <p className="pt-4">{`The ambiance is a blend of warmth and familiarity, adorned with mismatched furniture, the soft glow of pendant lights, and a collection of local art that reflects the spirit of our community. Here, the barista knows your name, and the laughter of friends blends seamlessly with the hum of the espresso machine.`}</p>
                            <p className="pt-4">{`Our menu is a celebration of the everyday, offering hearty sandwiches, homemade soups, and a selection of baked goods that evoke a sense of nostalgia. From the classic Americano to the comforting embrace of a freshly baked muffin, we believe that simplicity is the key to creating extraordinary moments.`}</p>
                            <p className="pt-4">{`So, whether you're stopping by for a quick coffee on your way to work or settling in for a leisurely chat with friends, Somerset Cafe is here to make your ordinary moments extraordinary. Welcome to a place where the extraordinary is found in the everyday.`}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
