import { Link } from "react-router-dom";

export default function Footer() {
    return (<>
        <div className="footer_wrap">
            <div className="container">
                <div className="footer_mn">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="nav_links">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/aboutus">About Us</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="address_f flxrow">
                                        <div className="iconbox flxfix"><img src="/assets/images/map_pin_icon.svg" alt="" /></div>
                                        <div className="in_address flxflexi">
                                            <p>Somerset Cafe</p>
                                            <p>135 Church St, <br />Richmond, VIC - 3121</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="timing_f flxrow">
                                        <div className="iconbox flxfix"><img src="/assets/images/time_icon.svg" alt="" /></div>
                                        <div className="in_address flxflexi">
                                            <p><strong>Tue-Fri:</strong> 5pm to 10pm</p>
                                            <p><strong>Sat:</strong> 12pm to 10pm</p>
                                            <p><strong>Sun:</strong> 12pm to 3pm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="conlinks">
                                <ul>
                                    <li><a href="tel:+61 3 9429 8589"><img src="/assets/images/footer_phone_icon.svg" alt="" />+61 3 9429 6008</a></li>
                                    <li><a href="www.somersetcafe.com.au"><img src="/assets/images/footer_web_icon.svg" alt="" />www.somersetcafe.com.au</a></li>
                                    <li><a href="mailto:somerset.cafe3121@gmail.com"><img src="/assets/images/footer_mail_icon.svg" alt="" />somerset.cafe3121@gmail.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_copyright flxrow">
                <p>Copyright Somerset Cafe MELBOURNE All Right Reserved.</p> <div class="developby">Developed By: <a href="https://cwdad.com/" target="_blank">CWDAD</a></div>
                    <div className="social_icons flxrow">
                        <a href="https://www.instagram.com/" target="_blank" className="icon-instagram_icon"></a>
                        <a href="https://www.facebook.com/" target="_blank" className="icon-facebook_icon"></a>
                        {/* <a href="#" className="icon-twitter_icon"></a> */}
                        <a href="#" className="icon-youtube_icon"></a>
                    </div>
                </div>
            </div>
            <div className="shape_1"><img src="/assets/images/flower_1.png" alt="" /></div>
        </div>
        <a href="https://api.whatsapp.com/send?phone=61410131339" target="_blank" class="wa_new">
            <img src="/assets/images//whatsapp.svg" />
        </a>
    </>)
}
