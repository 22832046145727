import { useState, useEffect, useRef, useContext } from "react";
import { GET_BOTTOMLESS_BRUNCH_TIME, POST_BOTTOMLESS_BRUNCH_BOOKING, GET_TABLE_BOOK_DETAIL, POST_CHECK_AVAILABLE_TABLE } from '../../helpers/url_helper';
import { is_login, img_view } from '../../helpers/Helper';
import { post, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { useHistory } from "react-router-dom";
import { MenuContext } from '../../layouts/Default';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from 'date-fns';

const StripeCheckout1 = require('react-stripe-checkout').default;

export default function Home(props) {
    let history = useHistory();
    const { menu, minimum_person_save_card_details } = useContext(MenuContext);

    const [BookingDetail, setBookingDetail] = useState({ booking_no: '', booking_type: 'Bottomless Brunch', date: '', time: '', first_name: '', last_name: '', number_of_people: 1, phone_number: '', email: '', post_code: '', note: '', customer_id: '', tc: true });
    const [dateList, setDateList] = useState([]);
    const [timeList, setTimeList] = useState([]);
    const [PartySize, setPartySize] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [ShowTc, SetShowTc] = useState(false);

    const [startDate, setStartDate] = useState('');

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [isLoader, setLoader] = useState(0);
    
    useEffect(() => {
        async function get_detaile() {
            var date_res_data = await get(GET_BOTTOMLESS_BRUNCH_TIME);
            if (date_res_data.status) {
                setDateList(date_res_data.data.date_array);
            }

            if (props.match.params.booking_id) {
                var res_data = await post(GET_TABLE_BOOK_DETAIL, { booking_no: props.match.params.booking_id });
                if (res_data.status) {
                    if (res_data.data.booking_detail.status != 'Confirmed') {
                        history.push("/");
                    }
                    setStartDate(new Date(res_data.data.booking_detail.date));
                    BookingDetail.booking_no = res_data.data.booking_detail.booking_no;
                    BookingDetail.date = res_data.data.booking_detail.data;
                    BookingDetail.time = res_data.data.booking_detail.time;
                    res_data.data.booking_detail.tc = true;
                    setBookingDetail(res_data.data.booking_detail);
                    for (var date_value of date_res_data.data.date_array) {
                        if (date_value.date == res_data.data.booking_detail.date) {
                            setTimeList(date_value.time);
                        }
                    }
                    CheckAvailableTable()
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [])

    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name == 'date') {
            for (var date_value of dateList) {
                if (date_value.date == event.target.value) {
                    setTimeList(date_value.time);
                }
            }
            BookingDetail.time = '';
            BookingDetail.date = event.target.value;
            BookingDetail.number_of_people = '';
            
            setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.value }));
            CheckAvailableTable();
        } else if (event.target.name == 'time') {
            BookingDetail.number_of_people = '';
            BookingDetail.time = event.target.value;
            setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.value }));
            CheckAvailableTable();
        } else if (event.target.name === 'tc') {
            setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.checked }));
        } else {
            setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.value }));
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            if (!BookingDetail.tc && (parseInt(BookingDetail.number_of_people) >= minimum_person_save_card_details)) {
                toast.error('Please accept terms and conditions');
                return false;
            }
            SetButtonDisabled(true);
            if (BookingDetail.customer_id || parseInt(BookingDetail.number_of_people) < minimum_person_save_card_details) {
                var res_data = await post(POST_BOTTOMLESS_BRUNCH_BOOKING, BookingDetail);
                if (res_data.status) {
                    sessionStorage.setItem('brunch_booking_no', res_data.data.booking_no);
                    history.push("/bottomless-brunch-success/"+res_data.data.booking_no);
                } else {
                    toast.error(res_data.message);
                }
                SetButtonDisabled(false);

            } else {
                window.$('.StripeCheckout1').click();
                setTimeout(() => {
                    SetButtonDisabled(false);
                }, 5000);
            }

        }
    }

    const handleToken = async (token, address) => {
        SetButtonDisabled(true);

        // form_inputs.product = product
        BookingDetail.token = token

        const response = await post(POST_BOTTOMLESS_BRUNCH_BOOKING, BookingDetail);
        if (response.status) {
            history.push("/bottomless-brunch-success/"+response.data.booking_no);
        } else {
            toast.error(response.message);
        }
        SetButtonDisabled(false);
    }

    const CheckAvailableTable = async (event) => {
        var res_data = await post(POST_CHECK_AVAILABLE_TABLE, { id: BookingDetail.booking_no, date: BookingDetail.date, time: BookingDetail.time });
        if (res_data.status) {
            setPartySize(res_data.booking_available)
        } else {
            toast.error(res_data.message);
        }
    }

    const setDate = (event) => {
        setStartDate(event);
        var date = new Date(event),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
        var new_date =  [date.getFullYear(), mnth, day].join("-");

        setTimeList([]);
        for (var date_value of dateList) {
            if (date_value.date == new_date) {
                setTimeList(date_value.time);
            }else{
            }
        }
        BookingDetail.time = '';
        BookingDetail.date = new_date;
        BookingDetail.number_of_people = '';

        setBookingDetail(inputs => ({ ...BookingDetail, ['date']: new_date }));
        CheckAvailableTable();
    }

    return (<>
        <div class="banner_wrapper">
            <div class="innerbanner">
                <div class="imagebox" style={{ "background-image": 'url(/assets/images/innerbanner.jpg)' }}></div>
                <div class="bann_captionbox">
                    <div class="container-fluid">
                        <h1>Bottomless Brunch</h1>
                        <div class="opentiming">100% Gluten Free</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="functionbooking_wrap">
            <div class="container">
                <div class="function_title">
                    <h2>Somerset Cafe Reservations</h2>
                </div>
                <div class="function_meta">
                    <div class="callaction"><img src="/assets/images/phone_icon.svg" alt="" />+61 3 9429 8589</div>
                    <div class="callaction"><img src="/assets/images/map_pin_icon_2.svg" alt="" />280 Bridge Rd, Richmond 3121</div>
                </div>
                {menu.bottomless_brunch_menu && <div className="text-center mb-5">
                    <a href={img_view(menu.bottomless_brunch_menu, '', '', false)} target="_blank" class="pdf_menu">View Bottomless Brunch Menu</a>
                </div>}
                {/* {(startDate && !timeList.length) && <div className="text-danger text-center">
                    What is Lorem Ipsum?
                </div>} */}
                <div class="function_form_wrap">
                    <form action="" onSubmit={handleSubmit}>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">Select Date</label>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={startDate}
                                        minDate={new Date()}
                                        maxDate={addMonths(new Date(), 6)}
                                        onChange={setDate}
                                        className="input_text"
                                        placeholderText="Select Date"
                                    />
                                    {/* <select name="date" value={BookingDetail.date} onChange={handleInputChange} class="input_text">
                                        <option value="">Select Date</option>
                                        {dateList && Object.entries(dateList).map(([key, date]) => {
                                            return (<option value={date.date} key={key}>{date.date}</option>);
                                        })}
                                    </select> */}
                                    {simpleValidator.current.message('date', BookingDetail.date, 'required')}
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">Start Time</label>
                                    <select name="time" value={BookingDetail.time} onChange={handleInputChange} class="input_text">
                                        <option value="">Select Time</option>
                                        {timeList && Object.entries(timeList).map(([key, date]) => {
                                            return (<option value={date.time} key={key}>{date.time}</option>);
                                        })}
                                    </select>
                                    {simpleValidator.current.message('time', BookingDetail.time, 'required')}
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">Party Size</label>
                                    <select name="number_of_people" value={BookingDetail.number_of_people} onChange={handleInputChange} class="input_text">
                                        <option value=''>Select Party Size</option>
                                        {Array.from(Array(PartySize), (e, i) => {
                                            return (<option value={++i}>{i}</option>)
                                        })}
                                    </select>
                                    {simpleValidator.current.message('number of people', BookingDetail.number_of_people, 'required')}
                                </div>
                            </div>
                            <div class="col-lg-12"><hr /></div>
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">First Name</label>
                                    <input type="text" name="first_name" value={BookingDetail.first_name} onChange={handleInputChange} class="input_text" placeholder="Your First Name" />
                                    {simpleValidator.current.message('first name', BookingDetail.first_name, 'required')}
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">Last Name</label>
                                    <input type="text" name="last_name" value={BookingDetail.last_name} onChange={handleInputChange} class="input_text" placeholder="Your Last Name" />
                                    {simpleValidator.current.message('last name', BookingDetail.last_name, 'required')}
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">Phone</label>
                                    <input type="text" name="phone_number" maxLength={12} value={BookingDetail.phone_number} onChange={handleInputChange} class="input_text" placeholder="Your Phone Number" />
                                    {simpleValidator.current.message('phone number', BookingDetail.phone_number, 'required|phone')}
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">Post Code</label>
                                    <input type="text" name="post_code" value={BookingDetail.post_code} onChange={handleInputChange} class="input_text" placeholder="Your Post Code" />
                                    {simpleValidator.current.message('post code', BookingDetail.post_code, 'required')}
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">Email</label>
                                    <input type="text" name="email" value={BookingDetail.email} onChange={handleInputChange} class="input_text" placeholder="Your Email Address" />
                                    {simpleValidator.current.message('email', BookingDetail.email, 'required|email')}
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_box">
                                    <label for="">Request/Occasion</label>
                                    <input type="text" name="note" value={BookingDetail.note} onChange={handleInputChange} class="input_text" placeholder="Your Note" />
                                </div>
                            </div>
                            {parseInt(BookingDetail.number_of_people) >= minimum_person_save_card_details && <div className="col-lg-12">
                                <div className="mt-4 ">
                                    <div className="checkrow">
                                        <input type="checkbox" name="tc" checked={BookingDetail.tc} onChange={handleInputChange} id="termsandcondition1" />
                                        <label htmlFor="termsandcondition1">Terms and Conditions - Cancellation Policy  <a href="javascript:;" onClick={() => { (ShowTc) ? SetShowTc(false) : SetShowTc(true) }} style={{ 'float': 'right' }}>View Terms</a></label>
                                        {ShowTc && <div>
                                            <p className="p_note" style={{ 'textAlign': 'left' }}>At this restaurant, a credit card is required to hold tables for parties of 5 or more. Please note, if you are group of 5 or more we require $10 per person deposit for confirmation of booking(It can be returned or taken out from your final bill). If you cancel, reduce the group size or fail to show up within a 48 hour notice your deposit won’t be returned.</p>
                                        </div>}
                                    </div>
                                </div>
                            </div>}
                            <div class="col-lg-12">
                                <div class="btnbox form_box">
                                    <button type="submit" disabled={ButtonDisabled} class="ondabtn bigbtn greenbtn" >{ButtonDisabled && <i className="spinner"></i>} Make Reservation</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <p className="p_note">Please note Saturdays and Sundays incur a 10% Weekend Surcharge. Public Holidays incur a 15% Surcharge.</p>
                    <div className="payment_note"><strong>Payments:</strong> VISA, MasterCard, Cash, Apple Pay, Android Pay, Debit</div>
                </div>
            </div>
        </div>

        <StripeCheckout1
            className="btn btn-success StripeCheckout1 none"
            stripeKey={process.env.REACT_APP_STRIPE_KEY}
            token={handleToken}
            email={BookingDetail.email}
            currency={process.env.REACT_APP_CURRENCY}
            amount={0}
            name={'We dont charge your card'}
            panelLabel="Make Reservation"
            billingAddress={false}
            alipay={false}
        />
    </>)
}
